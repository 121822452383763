import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagramSquare,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import PrivacyPolicyScreen from "../screens/PrivacyPolicy";

const Footer = () => {
  const footerYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <Container>
          <Row
            style={{
              height: "50px",
              backgroundColor: "#1091ab",
              marginBottom: "20px",
            }}
          >
            <Col
              style={{
                height: "50px",
                backgroundColor: "#1091ab",
                justifyContent: "space-evenly",
                alignItems: "center",
                display: "flex",
                color: "white",
              }}
              className="text-center py-3"
            >
              zpotter technologies LLP &copy; {footerYear}
              <Nav.Link href="https://www.facebook.com/profile.php?id=61560699947674">
                <FaFacebook style={{ height: "1.7rem", width: "1.7rem" }} />
              </Nav.Link>
              {/* <Nav.Link href="">
                <FaSquareXTwitter />
              </Nav.Link> */}
              <Nav.Link href="https://www.instagram.com/zpotter_technologies/?igsh=bGR2aWM0ZGdsZ283&utm_source=qr#">
                <FaInstagramSquare
                  style={{ height: "1.7rem", width: "1.7rem" }}
                />
              </Nav.Link>
              <Nav.Link href="https://www.youtube.com/@zpotter-gx8yw">
                <FaYoutube style={{ height: "1.7rem", width: "1.7rem" }} />
              </Nav.Link>
              <Nav.Link href="">
                <FaLinkedin style={{ height: "1.7rem", width: "1.7rem" }} />
              </Nav.Link>
            </Col>
            <Col
              className="text-center py-3"
              style={{
                height: "50px",
                backgroundColor: "#1091ab",
                justifyContent: "space-evenly",
                alignItems: "center",
                display: "flex",
                color: "white",
              }}
            >
              <Nav.Link href="/privacyPolicy">Privacy Policy</Nav.Link>
              <Nav.Link href="/terms-and-conditions">
                Terms and Conditions
              </Nav.Link>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
