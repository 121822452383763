import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import apartmentPic from "../assets/apartmentImage.avif";

const HousingSoftware = () => {
  return (
    <Container className="my-2">
      <Row
        style={{
          backgroundColor: "#0a5a6a",
        }}
      >
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          zpotter Technologies
        </h2>
      </Row>
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={apartmentPic} alt="1sAndOs" fluid />
        </Col>{" "}
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>
            {" "}
            Simplify Apartment and Gated Community Management with Our
            Comprehensive App
          </h2>
          <p style={{ textIndent: "3rem" }}>
            Managing apartments and gated communities has never been easier! Our
            app is designed to streamline the day-to-day operations of
            residential complexes, helping both management teams and residents
            stay connected and organized. With powerful features tailored for
            modern living, you can handle everything from resident registration
            and visitor tracking to payments and facility bookings. The app
            offers seamless communication, ensuring that important updates and
            notifications reach all residents instantly. You can track
            maintenance requests, manage security with visitor logs, and
            simplify payments with easy-to-use billing features. Residents can
            access important community information, book facilities, and stay
            updated with the latest events or announcements right from their
            mobile devices. Whether you manage a small apartment complex or a
            large gated community, our app helps you create a more connected,
            efficient, and secure living environment. Available in multiple
            languages, it’s designed to meet the needs of diverse communities.
          </p>
        </Col>{" "}
      </Row>{" "}
      {/* <Row className="align-items-center">
        <h2
          style={{
            color: "#0a5a6a",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {" "}
          Available Features
        </h2>
        <div
          style={{
            height: "100vh",
            backgroundColor: "#ecf3f2",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${gymPic2})`, // Dims the background image
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <ul
            style={{
              color: "Yellow",
              display: "flex", // Apply flexbox
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically (optional)
              flexDirection: "column", // Align list items vertically
              listStyle: "none", // Remove bullets
              padding: "30px", // Optional: to remove default padding
            }}
          >
            <li>Mobile Attendance using QR code</li>
            <li>Member Registration using QR code</li>
            <li>Attendance Management</li>
            <li>Member Management</li>
            <li>Member Profile Picture</li>
            <li>Gym Visitors Tracker</li>
            <li>Member Payments Tracker</li>
            <li>Gym Equipments Management</li>
            <li>Accounts & Finance Management</li>
            <li>Multiple Gym Admins</li>
            <li>Daily, Weekly, Monthly Analytics</li>
            <li>Daily Trends</li>

            <li>Reports Download </li>
            <li>Member Invoice Download</li>
            <li>Multiple Software Themes</li>
            <li>Use in Multiple Devices - Laptop and Mobiles</li>
            <li>Android Mobile App</li>
            <li>Automatic WhatsApp Notifications to Members</li>
            <li>Automatic WhatsApp Reminders to Members</li>
            <li>Send WhatsApp Promotions</li>
            <li>Software is Available in English and Tamil Languages also</li>
          </ul>
        </div>
      </Row>{" "} */}
    </Container>
  );
};

export default HousingSoftware;
