import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import gymPic from "../assets/gymPic.jpeg";
import gymPic2 from "../assets/gymPic2.avif";
import prgmNamesPic from "../assets/program names.webp";

const CorporateTraining = () => {
  return (
    <Container className="my-2">
      <Row
        style={{
          backgroundColor: "#0a5a6a",
        }}
      ></Row>
      <Row className="align-items-center">
        <h2 style={{ color: "#0a5a6a" }}>
          {" "}
          Empower Your Team with Cutting-Edge Skills!
        </h2>
        <p style={{ textIndent: "3rem" }}>
          Our Corporate Training programs are designed to help your workforce
          excel in the fast-evolving tech landscape. We specialize in teaching
          industry-relevant development frameworks and testing methodologies,
          enabling your team to:
        </p>
        <ul
          style={{
            display: "flex", // Apply flexbox

            flexDirection: "column", // Align list items vertically
          }}
        >
          <h5 style={{ color: "black" }}>
            <li>Master tools like React, Node.js, and Cypress.</li>
          </h5>
          <h5 style={{ color: "black" }}>
            <li>Enhance efficiency with agile testing techniques.</li>
          </h5>
          <h5 style={{ color: "black" }}>
            <li>Deliver bug-free, scalable solutions with confidence.</li>
          </h5>
        </ul>{" "}
        Build Your Skills with Our Corporate Training Programs! Upskill your
        team to drive innovation and stay competitive in today's tech landscape!
        We offer comprehensive training designed to enhance your team's
        proficiency in:
        <Col md={6}>
          <ul
            style={{
              display: "flex", // Apply flexbox

              flexDirection: "column", // Align list items vertically
            }}
          >
            <br />
            <h6 style={{ color: "black" }}>
              <li>
                <strong> Website Development:</strong> Create stunning websites
                using React, Angular, and Node.js.
              </li>
            </h6>
            <h6 style={{ color: "black" }}>
              <li>
                <strong>API Development & Testing:</strong> Learn to build and
                validate robust APIs with Postman and Swagger.
              </li>
            </h6>
            <h6 style={{ color: "black" }}>
              <li>
                <strong>Functional Testing:</strong> Deliver flawless
                applications by mastering testing strategies.
              </li>
            </h6>
            <h6 style={{ color: "black" }}>
              <li>
                <strong>Automation Testing:</strong> Save time and improve
                accuracy with tools like Selenium, Cypress, and Playwright.
              </li>
            </h6>

            <h6 style={{ color: "black" }}>
              <li>
                <strong>Mobile App Development:</strong> Build dynamic apps with
                frameworks like React Native and Flutter.
              </li>
            </h6>
            <h6 style={{ color: "black" }}>
              <li>
                <strong>Agile & DevOps Practices:</strong> Optimize workflows
                with CI/CD pipelines and agile methodologies.
              </li>
            </h6>
          </ul>{" "}
        </Col>
        <Col md={6}>
          <Image src={prgmNamesPic} alt="1sAndOs" fluid />
        </Col>
      </Row>{" "}
    </Container>
  );
};

export default CorporateTraining;
