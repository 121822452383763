import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import roboHumanPic from "../assets/robo human.jpg";
import prgmPic from "../assets/program.jpeg";
import prgmNamesPic from "../assets/program names.webp";
import roboPic from "../assets/robo.jpg";
import handsPic from "../assets/hands.avif";
import securityPic from "../assets/security.webp";

const HomeScreen = () => {
  return (
    <Container className="my-2">
      <Row
        style={{
          backgroundColor: "#0a5a6a",
        }}
      >
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          zpotter Technologies
        </h2>
      </Row>
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={roboHumanPic} alt="1sAndOs" fluid />
        </Col>{" "}
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>Meet the Innovation</h2>
          <p style={{ textIndent: "3rem" }}>
            Welcome to zpotter Technologies, where we specialize in delivering
            cutting-edge software solutions tailored to meet the unique needs of
            our clients. With a team of dedicated professionals, we pride
            ourselves on our ability to innovate and drive success through our
            comprehensive suite of services. From custom software development to
            robust cybersecurity solutions, our mission is to empower businesses
            to achieve their goals with efficiency and confidence. Our expertise
            spans a wide range of services, including mobile application
            development, web development, IT consulting, and much more. At
            zpotter Technologies, we are committed to providing exceptional
            value and support to our clients, helping them navigate the
            complexities of the digital landscape and stay ahead of the
            competition.
          </p>
        </Col>{" "}
      </Row>{" "}
      <p
        style={{
          backgroundColor: "transparent",
          fontSize: "1rem",
        }}
      >
        Join us on a journey of innovation and excellence, and discover how our
        solutions can transform your business operations and enhance your
        overall performance. Contact us today to learn more about what we can do
        for you.
      </p>{" "}
      <br />{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />
      <Row className="align-items-center">
        <Col
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>
            Empowering Businesses with Advanced Technology Solutions
          </h2>
          <p style={{ textIndent: "3rem" }}>
            We believe in harnessing the power of technology to unlock new
            opportunities for businesses. Our team of experts works closely with
            clients to develop innovative, scalable solutions that streamline
            operations and drive growth. Whether it's developing a custom mobile
            app, optimizing website performance, or implementing IT strategies,
            we are here to elevate your business to new heights. Our focus on
            quality, precision, and client satisfaction has earned us a
            reputation for delivering projects on time and within budget. With
            our cutting-edge solutions, you can enhance customer engagement,
            improve operational efficiency, and gain a competitive edge in your
            industry. Let zpotter Technologies be your trusted partner in
            navigating the fast-evolving digital world.
          </p>
        </Col>{" "}
      </Row>{" "}
      <br />{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />
      <Row className="align-items-center">
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <Image src={prgmPic} alt="1sAndOs" fluid />
        </Col>
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>
            Your Partner for Digital Transformation
          </h2>
          <p style={{ textIndent: "3rem" }}>
            zpotter Technologies is more than just a technology provider—we are
            your partner in digital transformation. Our mission is to help
            businesses adapt and thrive in the digital age by providing tailored
            solutions that address specific challenges and objectives. From
            streamlining business processes to securing sensitive data, our
            comprehensive services are designed to ensure your company stays
            ahead of the curve. We specialize in a wide array of services such
            as software integration, DevOps, UI/UX design, and much more. Our
            approach is simple: we listen, we understand, and we deliver
            solutions that make a lasting impact. Trust zpotter Technologies to
            guide you through every phase of your digital journey with
            confidence and success.
          </p>
        </Col>
      </Row>{" "}
      <br />{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />
      <Row className="align-items-center">
        <Col
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>Security-Focused Solutions</h2>
          <p style={{ textIndent: "3rem" }}>
            In today’s digital age, security is more important than ever, and at
            zpotter, it’s at the core of our service offerings. We are dedicated
            to ensuring the safety and confidentiality of our clients’ data with
            robust cybersecurity solutions and best practices. Our team stays
            ahead of evolving threats to offer cutting-edge protection and peace
            of mind. From secure application development to comprehensive IT
            infrastructure audits, we empower businesses to operate with
            confidence, knowing their digital assets are protected.
          </p>
        </Col>
      </Row>{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />{" "}
      <Row className="align-items-center">
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>
            Innovating for Tomorrow, Delivering Today
          </h2>
          <p style={{ textIndent: "3rem" }}>
            At zpotter Technologies, we are constantly pushing the boundaries of
            innovation to deliver solutions that prepare businesses for
            tomorrow's challenges. Our forward-thinking team is dedicated to
            creating technology-driven solutions that are not only practical but
            also transformative. We stay at the forefront of industry trends and
            advancements, ensuring our clients benefit from the latest
            developments in software and IT. Whether you're looking to enhance
            your cybersecurity measures, develop a custom API, or modernize your
            legacy systems, zpotter Technologies has the expertise and resources
            to deliver solutions that align with your vision. We pride ourselves
            on being a results-driven company, with a commitment to quality and
            excellence that our clients can rely on.
          </p>
        </Col>
        <Col md={6}>
          <Image src={roboPic} alt="1sAndOs" fluid />
        </Col>
      </Row>{" "}
      <br />{" "}
      <hr
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
          color: "#0a5a6a",
          margin: "auto",
        }}
      />
      <br />
      <Row className="align-items-center">
        <Col md={6}>
          <Image src={handsPic} alt="1sAndOs" fluid />
        </Col>
        <Col
          md={6}
          style={{
            marginTop: "2rem",
            padding: "2rem",
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <h2 style={{ color: "#0a5a6a" }}>Integrity-Driven Approach</h2>
          <p style={{ textIndent: "3rem" }}>
            Integrity is the foundation of everything we do. We believe in
            maintaining transparency and honesty in all our dealings, ensuring
            that our clients can trust us to deliver on our promises. Our team
            is dedicated to upholding the highest ethical standards, providing
            solutions that not only meet but exceed expectations. Whether we are
            designing custom software or offering IT consulting services, we are
            committed to working with integrity and building long-lasting
            relationships based on trust.
          </p>
        </Col>
      </Row>{" "}
      <br /> <br />
      <br /> <br />
    </Container>
  );
};

export default HomeScreen;
