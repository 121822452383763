import React from "react";
import { Card } from "react-bootstrap";

function TermsAndConditions() {
  return (
    <div>
      <h2 style={{ color: "#0a5a6a" }}>Terms and Conditions</h2>
      <Card
        style={{
          marginTop: "2rem",
          padding: "2rem",
          backgroundColor: "transparent",
          fontSize: "0.9rem",
        }}
      >
        <div>
          <h5 style={{ color: "#0a5a6a" }}>Definitions</h5>
          <p>
            <li>
              A "User" is anyone who accesses, browses, crawls, scrapes, or in
              any way uses the Site. The terms "you" and “your" refer to you, as
              a User of the Site. The terms "we", "us", and "zpotter" refer to
              zpotter. "Terms" or "Terms of Service" refer to the terms
              contained in this Agreement.
            </li>
            <li>
              "Content" means text, images, photos, audio, video, and all other
              forms of data or communication. "Your Content" means Content that
              you submit, post, or transmit to, or are using on, the Site, such
              as communications, workspace posts, reviews, compliments,
              information that you display as part of your account profile,
              invitations, or other data that you may submit, post, or transmit
              to, or are using on, the Site. Your Content does not include the
              information that you submit to create an account on the Site.
              "User Content" means Content that Users submit, post, or transmit
              to, or are using on, the Site. "zpotter Content" means Content
              that is created by zpotter and made available on the Site. "Third
              Party Content" means Content that is made available on the Site by
              parties other than zpotter or the Users, such as data providers
              that license data for use on the Site. "Site Content" means all
              the Content that is made available on the Site, including Your
              Content, User Content, Third Party Content, and zpotter Content.
            </li>
            <li>
              “Public Content” means the information that you submit or post to
              the zpotter Sites for public display, such as information that you
              display as part of your public profile or any other social or
              public submission areas that zpotter might make optionally
              available for you in the future.
            </li>
            <li>
              {" "}
              “Private Content” means the “User Content” that you submit, post,
              or transmit to the non-public areas of the site, including but not
              limited to secure workspaces.
            </li>
          </p>
          <h5 style={{ color: "#0a5a6a" }}>Acceptance</h5>
          <p>
            Your use of the Service is governed by the following terms of
            service (“Terms”). Please review the Terms carefully before using
            the Service. Do not use the Service if you do not accept the Terms.
            You can only accept these Terms if you are a business or acting on
            behalf of a business. You have the right to have a record of these
            Terms and any related Agreement you enter with zpotter made
            available on paper form upon request for no additional fee. Simply
            e-mail contactus@zpotter.in with such a request.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>User Accounts</h5>
          <p>
            You represent that you are authorized to create and access an
            account. Through the user process, you will establish a username and
            password for access to your online account. You are responsible for
            maintaining the confidentiality of your username, account data, and
            password. You agree to comply with all billing procedures, including
            providing and maintaining accurate and lawful billing information
            for active zpotter accounts. You agree that you are solely
            responsible for all acts or omissions that occur under your account
            or password, including the Content of your account as used
            throughout the Service. You will immediately notify zpotter of any
            unauthorized use of your password or account. You are prohibited
            from soliciting Users of this Site including Consultants for any
            purpose (including inviting other Users to contact you outside the
            Site or inviting Consultants to participate in a competing website)
            except with advance written consent. Without limiting any other
            remedies, zpotter may suspend or terminate your account if we
            suspect, in our sole judgment, that you (by conviction, settlement,
            insurance or escrow investigation, or otherwise) have engaged in
            fraudulent activity in connection with the Site or have engaged in
            activities in violation of these Terms or any related Agreements
            with zpotter.
          </p>
          <h5 style={{ color: "#0a5a6a" }}>Contact and Violations.</h5>
          <p>
            Please contact us with any questions regarding the Terms. Please
            report any violations of the Terms to our legal department through
            the contacts page or contactus@zpotter.in
          </p>
          <h5 style={{ color: "#0a5a6a" }}>
            Changes to this Terms and Conditions
          </h5>
          <p>
            We reserve the right to alter this Terms at any time. Such
            alterations will be posted on our website or App. You can also
            obtain an up-to-date copy of our Terms by contacting us. This
            document was last updated on October 16, 2024
          </p>
        </div>
      </Card>
    </div>
  );
}

export default TermsAndConditions;
