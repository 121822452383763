import { React } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../assets/contactImage.png";

const ContactScreen = () => {
  return (
    <Container className="my-5">
      <Row className="align-items-center">
        {" "}
        <Col md={8}>
          <Image src={logo} alt="1sAndOs" fluid />
        </Col>
        <Col
          md={4}
          style={{
            backgroundColor: "transparent",
            fontSize: "1rem",
          }}
        >
          <label style={{ color: "#0a5a6a" }}>WhatsApp or Call -{"  "}</label>
          7806873973 , 9092913756
          <br />
          <label style={{ color: "#0a5a6a" }}>Email : </label>{" "}
          Contactus@zpotter.in
          <br></br>
          <br></br>
          <h5 style={{ color: "#0a5a6a" }}>Address</h5>
          No 7, Vivekanandar street, <br></br>Rajakilpakam,<br></br> Chennai -
          600073.
          <br></br> <br></br>
          <h5 style={{ color: "#0a5a6a" }}>Registered Office</h5>
          No 279/5, New Colony, <br></br> Keelairal, Ettayapuram,
          <br></br> Tuticorin, TamilNadu - 628908
          <br></br>
          <br></br> <h3 style={{ color: "#0a5a6a" }}>Working Hours</h3>
          Monday to Friday
          <br></br>Timing : 10 AM - 6 PM
        </Col>
      </Row>
    </Container>
  );
};

export default ContactScreen;
